.header {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding: 10px;
}

.aboutus {
  background-color: #fff;
  color: black;
  width: 100%;
  text-align: left !important;
  padding: 30px;
}
.aboutus h3 {
  margin-top: 3px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  font-style: normal;
  color: #323232;
  padding: 10px 0px;
}
.card {
  padding-bottom: 10px;
  border-bottom: 1px solid silver;
}

.card p {
  font-size: 16px;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 150%;
}

.box {
  border-radius: 7px;
  border: 1px solid silver;
  height: 280px;
  text-align: center;
  vertical-align: top;
  margin-top: 8px;
  background-repeat: no-repeat;

  background-size: contain;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.box p {
  font-size: 15px;
  font-weight: 300;
  line-height: 14px;
  padding-top: 20px;
  text-transform:uppercase ;
}
