html, body {
    font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
    font-size: 13px;
   background-color: #f5f5f5;
}
body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-family: "Montserrat", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
.box{
  border: 1px solid red;
}
.link
{
  cursor: pointer;
}
.pad-5{
  padding: 5px;
}
.pad-t-5{
  padding-top: 5px;
}
.pad-r-5{
  padding-right: 5px;
}
.pad-b-5{
  padding-bottom: 5px;
}
.pad-l-5{
  padding-left: 5px;
}
row{
  padding: 5px;;
}

.align-rt{
  text-align: right;
}

.align-lt{
  text-align: left;
}
.align-ct{
  text-align: center;
}


.header {
  background-color: #404040;
  color: #d9d9db;
  padding: 10px;
  font-size: 20px;
}
.page-content{
  background-color: #FFF;
}



.box {
  border-radius: 7px;
  border: 1px solid silver;
  height: 300px;
  text-align: center;
  vertical-align: top;
  margin-top: 8px;
  background-repeat: no-repeat;

  background-size: contain;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.box p {
  font-size: 15px;
  font-weight: 300;
  line-height: 14px;
  padding-top: 20px;
  text-transform:uppercase ;
}

.vh_middle {
        display: inline-block;
        vertical-align: middle;
        line-height: normal;
    }