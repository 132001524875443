.desc {
  height: 300px;
  text-align: left;
  letter-spacing: 1px;
  word-spacing: 2px;
  font-size: 115%;
  padding-top: 10px;
  vertical-align: middle!important;
  line-height: normal !important;
  display: table;
}
.desc span{
  display: table-cell;
  vertical-align: middle;
  padding: 10px;
}
.title {
  font-size: 150% !important;
  font-weight: 300;
  line-height: 14px;
  padding-top: 20px;
  text-transform: uppercase;
}
.box {
  border-radius: 7px;
  border: 1px solid silver;
  height: 300px;
  text-align: center;
  vertical-align: top;
  margin-top: 8px;
  background-repeat: no-repeat;
  padding: 30px;
  background-size: contain;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}
.box p {
  font-size: 15px;
  font-weight: 300;
  line-height: 14px;
  padding-top: 20px;
  text-transform: uppercase;
}

@media (min-width: 480px) and (max-width: 767px) {
  .box {
    height: 250px;
  }
}
@media (min-width: 767px) and (max-width: 989px) {
  .box {
    height: 270px;
  }
}
@media (min-width: 989px) {
  .box {
    height: 300px;
  }
}
