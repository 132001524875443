.schTag {
    border: 1px solid #808080;
    padding: 5px 5px;
    margin: 2px;
    float: left;
    
    cursor: pointer;
    -webkit-border-radius: 5px !important;
    -moz-border-radius: 5px !important;
    border-radius: 5px !important;
}