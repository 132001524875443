.wrapper {
  background-color: #fff;
  color: black;
  width: 100%;
  text-align: left !important;
  
  padding: 30px;
}
.header {
  font-size: 16px;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 200%;

  text-align: center;
  padding: 10px;
}
h2{
     font-size: 16px;
  letter-spacing: 1px;
  word-spacing: 1px;
  line-height: 200%; 
}
