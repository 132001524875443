.banner {
  height: 580px !important;
  padding-top: 0px !important;
  background-repeat:no-repeat;
  background-size:100% 100%;
}
.banner p {
  padding: 60px 10px;
  font-size: 40px;
  font-weight: 200;
  color: #403030;
}

@media (max-width: 480px)  {
  .banner {
  height: 380px !important;
 
}
  .banner p {
    font-size: 20px!important;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .banner {
  height: 380px !important;
 
}
  .banner p {
    font-size: 20px!important;
  }
}
@media (min-width: 767px) and (max-width: 989px) {
  .banner p {
    font-size: 30px;
  }
}
@media (min-width: 989px) {
  .banner p {
    font-size: 40px;
  }
}
