.header {
  font-size: 20px;
  font-weight: bold;
  text-align: left;
  padding: 10px;
}

.contactus {
  background-color: #fff;
  color: black;
  width: 100%;
  text-align: left !important;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  padding-left: 50px;
}
.contactus h3 {
  margin-top: 3px;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  font-style: normal;
  color: #323232;
  padding: 10px 0px;
}
.card {
  padding-bottom: 10px;
  border-bottom: 1px solid silver;
}

.form {
  padding: 1rem;
}

.control {
  margin-bottom: 0.5rem;
}

.control label {
  display: block;
  font-weight: bold;
  margin-bottom: 0.6rem;
}

.control input,
.control textarea {
  display: block;
  font: inherit;
  border-radius: 4px;
  border: 1px solid #ccc;
  padding: 0.7rem;
  width: 100%;
}

.actions {
  margin-top: 1rem;
  text-align: right;
}

.actions button {
  font: inherit;
  cursor: pointer;
  background-color: #77002e;
  color: white;
  padding: 0.5rem 1.5rem;
  border: 1px solid #77002e;
  border-radius: 4px;
  font-weight: bold;
}

.actions button:hover,
.actions button:active {
  background-color: #a50e48;
  border-color: #a50e48;
}

.success {
  color: green;
  font-size: 110%;
}
.error {
  color: red;
  font-size: 110%;
}
