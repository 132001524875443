.header {
  background-color: #404040;
  color: #d9d9db;
  padding: 10px;
}
.header_links {
  /*display: flex;
width: 100%;
 justify-content: space-between;
  align-items: center;
  background-color: #77002e;
  
  padding: 20px 10%; padding: 10px;
  */
  background-color: #404040;
  color: #d9d9db;
}
.header_links a {
  text-decoration: none;

  font-size: 20px;
  color: #fff !important;
}
.header_links a:hover,
.header_links a:active,
.header_links a.active {
  color: #a50e48 !important;
}

.logo {
  font-size: 2rem;
  color: white;
  font-weight: bold;
  height: 70px;
}
.logo img {
  /*width: 280px;*/
  height: 70px;
}
.info {
  color: #fff;
  line-height: 2;
  padding-top: 5px;
  text-align: right !important;
}

@media (min-width: 767px) and (max-width: 989px) {
  .header_links a {
    font-size: 16px;
  }
}
@media (min-width: 480px) and (max-width: 767px) {
  .logo {
    text-align: center !important;
  }
  .info {
    text-align: center !important;
  }
  .header_links {
    text-align: center !important;
  }
  .header_links a {
    font-size: 12px !important;
  }
  .contact {
    padding-bottom: 20px;
  }
}
@media (max-width: 480px) {
  .logo {
    text-align: center !important;
  }
  .header_links {
    text-align: center !important;
  }
  .info {
    text-align: center !important;
  }
  .header_links a {
    font-size: 12px !important;
  }
}
/*
.header ul {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  align-items: baseline;
}

.header li {
  margin-left: 3rem;
}

.header a {
  text-decoration: none;
  font-size: 1.5rem;
  color: #fff;
}

.header a:hover,
.header a:active,
.header a.active {
  color: rgb(247, 177, 97);
}
*/
