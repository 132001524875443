.wrapper {
  background-color: #fff;
  color: black;
  width: 100%;
  text-align: left !important;

  padding: 15px;
}
.schTag {
  border: 1px solid #808080;
  padding: 5px 5px;
  margin: 2px;
  float: left;

  cursor: pointer;
  -webkit-border-radius: 5px !important;
  -moz-border-radius: 5px !important;
  border-radius: 5px !important;
}
.schWrp {
  padding-bottom: 10px;
  border-bottom: 1px solid silver;
}

.gallery {
  padding: 0px !important;
  height: 300px;
  background-repeat: no-repeat;
  cursor: pointer;
  margin: 5px;
  max-height: 100%;
  display: block; /* remove extra space below image */
  background-size: contain;
  -moz-background-size: 100% 100%;
  -webkit-background-size: 100% 100%;
  background-size: 100% 100%;
}

.gallery:hover {
  opacity: 0.8;
}
.model {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  transition: opacity 0.4s ease, visibility 0.4s ease,
    transform 0.5s ease-in-out;
  visibility: hidden;
  opacity: 0;
  transform: scale(0);
  overflow: hidden !important;
  z-index: 999;
}

.model .open {
  visibility: visible;
  opacity: 1;
  transform: scale(1);
}
.model img {
  width: auto;

  height: 100%;

  display: block;
  line-height: 0px;
  box-sizing: border-box;
  padding: 10px !important;
  margin: 1 auto;
}
.model .open svg {
  position: fixed;
  top: 10px;
  right: 10px;
  width: 2rem;
  height: 2rem;
  padding: 5rem;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  cursor: pointer;
}
.closeBtn {
  /*
  max-height: 90% !important;
  */
}

@media  (min-width: 10px) and (max-width: 479px) {
  .mmodalImg {
    max-height: 200px;
  }
}

@media (min-width: 480px) and (max-width: 767px) {
  .mmodalImg {
    max-height: 300px;
  }  
  body { background: red; }
}
@media screen and (max-width: 480px) {

  .mmodalImg {
    max-height: 300px;
  }
}

@media (min-width: 767px) and (max-width: 989px) {
  .modalImg {
    max-height: 450px;
  }
  
}
@media (min-width: 989px) {
  .modalImg {
    max-height: 650px;
  }
}

