.main {
  

  background-color: #edefed;
  color: black;
  text-align: center!important;
 /* border: 1px white dotted;
  padding: 15px 5px;*/
  
}
