.footer {
  background-color: #404040;
  color: #d9d9db;
  padding: 10px;
}
.footer a {
  text-decoration: none;

  color: #fff !important;
}

.footer a:hover,
.footer a:active,
.footer a.active {
  /*
  color: rgb(247, 177, 97) !important;
  */
  color: #a50e48!important;

}

.footer_clause {
  color: #999;
}
.tou {
  text-align: right !important;
}
.tou span {
  cursor: pointer;
  color: #428bca;
  text-decoration: none;
}
.clients {
  font-size: 19px;
  font-weight: bold;
}

.footer_cr {
    text-align: right !important;
  }
  .footer_sm {
    text-align: right    !important;
  }






@media (min-width: 480px) and (max-width: 767px) {
  .tou {
    text-align: center !important;
  }
  .footer_clause {
    text-align: center !important;
  }
  .footer_cr {
    text-align: center !important;
  }
  .footer_sm {
    text-align: center !important;
  }
}
